<template>
    <vx-card v-if="this.data" title="Purchase Order Detail">
        <div class="mb-6 vx-row" style="width: 50%">
            <vs-button class="mt-2 ml-4" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="mb-6 vx-row" style="width: 50%" v-if="readonly && id">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Code</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="this.data.code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Supplier</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="this.data.supplier.name" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Payment Term</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="this.data.payment_term.name" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Purchase Group</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="this.data.purchase_group.name" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Purchase Type</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="this.data.purchase_type.name" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Warehouse</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="`${this.data.warehouse.code} - ${this.data.warehouse.name}`" :readonly="true"
                    class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Est. Time Arrived</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="formatDate(this.data.eta)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Source</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="this.data.sources" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Currency</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-input :value="`${this.data.currency.code} - ${this.data.currency.description} `" :readonly="true"
                    class="w-full"></vs-input>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="flex items-center w-full vx-col sm:w-1/3">
                <span>Notes</span>
            </div>
            <div class="w-full vx-col sm:w-2/3">
                <vs-textarea :readonly="true" v-model="this.data.notes" width="100%" />
            </div>
        </div>
        <div v-if="this.data.purchase_charges" class="mb-6 vx-row">
            <div class="w-full vx-col">
                <div class="flex items-center w-full vx-col sm:w-1/3">
                    <h5>Charges</h5>
                </div>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%">Name</th>
                            <th width="20%">amount</th>
                            <th width="15%">Tax</th>
                            <th width="20%">Tax Amount</th>
                            <th width="20%">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(charge, index) in this.data.purchase_charges" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="charge.charge.name" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="formatLocalNumber(charge.amount)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" v-model="charge.tax_model.name" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(charge.tax_amount)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(charge.sub_total)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3"></td>
                            <th>Total Charge</th>
                            <td><vs-input :readonly="true" style="width: auto;" :value="formatLocalNumber(this.data.total_charge)" />
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3"></td>
                            <th>Total Tax</th>
                            <td><vs-input :readonly="true" style="width: auto;" :value="formatLocalNumber(this.data.total_charge_tax)" /></td>
                        </tr>
                        <tr>
                            <td colspan="3"></td>
                            <th>Grand Total</th>
                            <td><vs-input :readonly="true" style="width: auto;" :value="formatLocalNumber(this.data.total_charge_grand)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="this.data.purchase_detail" class="mb-6 vx-row">
            <div class="w-full vx-col">
                <div class="flex items-center w-full vx-col sm:w-1/3">
                    <h5>Order Details</h5>
                </div>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="15%">Item Name</th>
                            <th width="10%">Units</th>
                            <th width="15%">Qty</th>
                            <th width="10%">Price</th>
                            <th width="10%">Subtotal</th>
                            <th width="10%">Discount</th>
                            <th width="10%">Net</th>
                            <th width="10%">Tax</th>
                            <th width="10%">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.data.purchase_detail" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="item.item_name" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="item.item_unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(item.quantity)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(item.price)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(item.subtotal)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(item.discount)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(item.net)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(item.tax)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" :value="formatLocalNumber(item.total)" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                            <th width="10%">Total Price List</th>
                            <td width="10%"><vs-input :readonly="true" type="text" :value="formatLocalNumber(this.data.item_price_list)" /></td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                            <th width="10%">Total Discount</th>
                            <td width="10%"><vs-input :readonly="true" type="text" :value="formatLocalNumber(this.data.item_total_discount)" /></td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                            <th width="10%">Total Purchase</th>
                            <td width="10%"><vs-input :readonly="true" type="text" :value="formatLocalNumber(this.data.item_total_purchase)" /></td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                            <th width="10%">Total Tax</th>
                            <td width="10%"><vs-input :readonly="true" type="text" :value="formatLocalNumber(this.data.item_total_tax)" /></td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                            <th width="10%">Grand Total</th>
                            <td width="10%"><vs-input :readonly="true" type="text" :value="formatLocalNumber(this.data.item_total_grand)" /></td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                            <th width="10%">Final Total</th>
                            <td width="10%"><vs-input :readonly="true" type="text" :value="formatLocalNumber(this.data.final_total)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mb-6 vx-row" style="width: 50%">
            <div class="w-full vx-col sm:w-1/1">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mt-2 ml-4" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
            data: null,
            id: null,
            readonly: true,
            code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            file: null,
            fileUrl: '',
        }
    },

    mounted() {
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()
        this.print = this.$route.query.print
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.purchaseOrder.baseUrlPath}/${this.id}`)
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        const data = resp.data
                        data.total_charge = 0;
                        data.total_charge_tax=0;
                        data.total_charge_grand=0;
                        if (data.purchase_charges) {
                            data.purchase_charges.forEach((v, i) => {
                                data.purchase_charges[i].sub_total = Number(v.amount) + Number(v.tax_amount);
                                data.total_charge += Number(v.amount);
                                data.total_charge_tax+=Number(v.tax_amount);
                                data.total_charge_grand +=Number(v.amount) + Number(v.tax_amount);
                            });
                        }

                        data.item_price_list = 0;
                        data.item_total_discount = 0;
                        data.item_total_purchase = 0;
                        data.item_total_tax=0;
                        if (data.purchase_detail) {
                            data.purchase_detail.forEach((v, i) => {
                                data.purchase_detail[i].total = Number(v.net) + Number(v.tax);
                                data.item_price_list += Number(v.subtotal);
                                data.item_total_discount += Number(v.discount)
                                data.item_total_tax += Number(v.tax)
                            });
                        }
                        data.item_total_purchase = Number(data.item_price_list)+Number(data.item_total_discount);
                        data.item_total_grand = Number(data.item_total_purchase)+Number(data.item_total_tax);

                        data.final_total = Number(data.total_charge_grand) + Number(data.item_total_grand)
                        this.data = data;
                        this.$vs.loading.close();
                        if (this.print) {
                            setTimeout(() => {
                                this.handlePrint()
                            }, 2000);
                        }
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-approval",

            });
        },
        async handlePrint() {
            var title = document.title
            document.title = `${this.data.code}-${this.data.warehouse.name}`
            await window.print();
            document.title = title
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>